import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'
import clsx from 'clsx'
import { useEmblaCarousel } from 'embla-carousel/react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MediaLoader from '@oakwood/oui/MediaLoader'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import { productType } from 'utils'
import ProductDetailsDigital from 'containers/ProductDetailsDigital'
import ProductFlowChoices from 'containers/ProductFlowChoices'
import ProductFlowControl from 'containers/ProductFlowControl'
import ProductFlowCta from 'containers/ProductFlowCta'
import ArrowPrevIcon from 'components/icons/ArrowPrev'
import ArrowNextIcon from 'components/icons/ArrowNext'
import IconButton from 'components/IconButton'
import Section from 'components/Section'
import GtmTracker, { gtmFormatProduct } from 'containers/Gtm/GtmTracker'
import ProductFlowPopup from 'containers/ProductFlowPopup'
import ProductFlowPopupContainer from 'containers/ProductFlowPopupContainer'
import ProductFlowCtaDecorator from 'containers/ProductFlowCtaDecorator'
import MuiAccordion from 'components/Accordion'
import MuiAccordionDetails from 'components/AccordionDetails'
import MuiAccordionSummary from 'components/AccordionSummary'
import AddIcon from 'components/icons/Add'
import Typography from 'components/Typography'
import ProductFlowNotification from 'containers/ProductFlowNotification'
import InfoCircle from '../../components/icons/InfoCircle'

const BREAKPOINT_KEY_UP = 'md'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      ...theme.mixins.horizontalBorders(theme.palette.divider),
      display: 'grid',
      gridTemplateColumns: 'minmax(300px, 1fr) minmax(300px, 500px)',
    },
  },
  mediaArea: {
    position: 'relative',
    overflow: 'hidden',
  },
  detailsArea: {
    padding: theme.spacing(4, 'var(--coa-container-spacing)'),
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      padding: theme.spacing(6, 'var(--coa-container-spacing)'),
    },
    '& > *': {
      marginBottom: theme.spacing(6),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  imageRoot: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      ...theme.mixins.absolute(theme.spacing(6), 'var(--coa-container-spacing)'),
      '& *': {
        height: '100%',
        width: '100%',
      },
    },
  },

  emblaRoot: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      ...theme.mixins.absolute(theme.spacing(6), 'var(--coa-container-spacing)'),
      '& *': {
        height: '100%',
      },
    },
  },
  emblaWrapper: {
    display: 'flex',
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      margin: theme.spacing(0, -1),
    },
  },
  emblaSlide: {
    position: 'relative',
    flexShrink: 0,
    width: `calc(100% - ${theme.spacing(8)}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(50% - ${theme.spacing(4)}px)`,
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      padding: theme.spacing(0, 1),
      '& *': {
        width: 'inherit',
      },
    },
  },
  navigationButton: {
    position: 'absolute',
    zIndex: 9,
    bottom: theme.spacing(3),
    display: 'none',
    minWidth: 100,
    justifyContent: 'stretch',
    transform: 'translateY(50%)',
    '& svg': {
      flexGrow: 1,
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  navigationPrev: {
    left: 'var(--coa-container-spacing)',
  },
  navigationNext: {
    right: 'var(--coa-container-spacing)',
  },
  mediaContainer: {
    [theme.breakpoints.up(BREAKPOINT_KEY_UP)]: {
      // Disable `MediaReveal` aspect-ratio for desktop
      '&:before': { content: 'none' },
      '& > *': { position: 'static' },
    },
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.complex,
    }),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 640,
    },
  },

  accordionExpandIcon: {
    '[class*="expanded"] &': {
      transform: 'none',
      '& path.plus': {
        display: 'none',
      },
    },
  },
  infoLabelContainer: {
    width: '100%',
    backgroundColor: '#D9C4B4',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 16,
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(3),
  },
}))

function ProductHeroOther(props) {
  const {
    className,
    product,
    text,
    content,
    selectionType,
    txtAlign = 'left',
    showPriceReview = false,
    imageOrientation = 'portrait',
    accordionItems,
    accordionHeight = 0,
    packageShot,
    priceLabel,
    infoLabel,
    reviewProductCodes,
  } = props

  const classes = useStyles(props)
  const t = useTranslations()

  const defaultPrice = product?.prices?.find((price) => price?.lineType === 'R')

  const [selectedPrice, setSelectedPrice] = React.useState(defaultPrice || { lineType: 'R' })

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'center',
    containScroll: 'keepSnaps',
  })

  const handlePrev = React.useCallback(() => embla && embla.scrollPrev(), [embla])
  const handleNext = React.useCallback(() => embla && embla.scrollNext(), [embla])
  const isEmpty = (obj) => Object.keys(obj).length === 0

  const handleMediaLoaded = React.useCallback(() => {
    embla?.reInit() // Fixes unresponsive desktop bug
  }, [embla])

  const [expanded, setExpanded] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const elementRef = useRef(null)

  const stock =
    product?.variants?.find((variant) => variant?.id === product?.variantId)?.outOfStock || false

  return (
    <>
      {product && !isEmpty(product) && (
        <Section className={clsx(classes.root, className)}>
          <GtmTracker
            event="productDetailView"
            data={{
              ecommerce: {
                detail: {
                  actionField: { action: 'Detail' },
                  products: [gtmFormatProduct(product)],
                },
              },
            }}
          />

          {product.imagesData?.length === 1 ? (
            <div className={classes.mediaArea}>
              <div className={classes.imageRoot}>
                <Image
                  className={classes.mediaContainer}
                  src={packageShot || product.imagesData[0]?.imageUrl}
                  objectFit={'cover'}
                  width={1000}
                  height={950}
                  alt="Product Hero Image"
                />
              </div>
            </div>
          ) : (
            <div className={classes.mediaArea}>
              <div className={classes.emblaRoot} ref={emblaRef}>
                <MediaLoader className={classes.emblaWrapper} onLoaded={handleMediaLoaded}>
                  {product.imagesData?.map(
                    (image, idx) =>
                      image.tag === 'Hero_R' && (
                        <div key={idx} className={classes.emblaSlide}>
                          <img
                            src={image.imageUrl}
                            alt={image.description}
                            width={
                              imageOrientation === 'portrait'
                                ? elementRef.current?.clientHeight / 1.55
                                : elementRef.current?.clientHeight / 0.775
                            }
                            height={elementRef.current?.clientHeight}
                          />
                        </div>
                      ),
                  )}
                </MediaLoader>
              </div>

              <IconButton
                className={clsx(classes.navigationButton, classes.navigationPrev)}
                onClick={handlePrev}
                edge="start"
                aria-label="Previous slide"
              >
                <ArrowPrevIcon />
              </IconButton>

              <IconButton
                className={clsx(classes.navigationButton, classes.navigationNext)}
                onClick={handleNext}
                edge="end"
                aria-label="Next slide"
              >
                <ArrowNextIcon />
              </IconButton>
            </div>
          )}

          <div ref={elementRef} className={classes.detailsArea}>
            <ProductDetailsDigital
              product={product}
              text={text}
              content={content}
              txtAlign={txtAlign}
            />

            <div style={{ height: Number(accordionHeight) }}>
              {accordionItems?.map((item, idx) => {
                const id = `coa-block-accordion-${idx}`

                return (
                  <MuiAccordion key={idx} expanded={expanded === id} onChange={handleChange(id)}>
                    <MuiAccordionSummary
                      classes={{ expandIcon: classes.accordionExpandIcon }}
                      expandIcon={<AddIcon fontSize="small" />}
                      aria-controls={`${id}-content`}
                      id={`${id}-header`}
                    >
                      <Typography variant="button">{item.summary}</Typography>
                    </MuiAccordionSummary>

                    <MuiAccordionDetails>
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{ __html: item.details }}
                      />
                    </MuiAccordionDetails>
                  </MuiAccordion>
                )
              })}
            </div>

            {!stock ? (
              infoLabel &&
              infoLabel !== '' && (
                <div className={classes.infoLabelContainer}>
                  <InfoCircle />
                  <Typography variant="body2">{infoLabel}</Typography>
                </div>
              )
            ) : (
              <div className={classes.infoLabelContainer}>
                <InfoCircle />
                <Typography variant="body2">
                  {infoLabel && infoLabel !== '' ? infoLabel : t('Web.Product.OutOfStock')}
                </Typography>
              </div>
            )}

            <ProductFlowControl product={product} setSelectedPrice={setSelectedPrice}>
              {!stock ? (
                <>
                  {selectionType === 'scrollup' && (
                    <ProductFlowPopupContainer>
                      <ProductFlowPopup>
                        <ProductFlowChoices />
                      </ProductFlowPopup>

                      <ProductFlowCta />
                    </ProductFlowPopupContainer>
                  )}
                  {selectionType === 'grid' && (
                    <>
                      {showPriceReview ? (
                        <>
                          <ProductFlowChoices />

                          <ProductFlowCtaDecorator
                            priceLabel={priceLabel}
                            reviewProductCodes={reviewProductCodes}
                            showFromPrice={product?.prices?.length > 1}
                            selectedPrice={selectedPrice}
                          >
                            <ProductFlowCta selectionType={selectionType} />
                          </ProductFlowCtaDecorator>
                        </>
                      ) : (
                        <>
                          <ProductFlowChoices />
                          <ProductFlowCta />
                        </>
                      )}
                    </>
                  )}

                  {selectionType === 'onbutton' && (
                    <>
                      {showPriceReview ? (
                        <ProductFlowCtaDecorator
                          priceLabel={priceLabel}
                          reviewProductCodes={reviewProductCodes}
                          showFromPrice={product?.prices?.length > 1}
                          selectedPrice={selectedPrice}
                        >
                          <ProductFlowCta selectionType={selectionType} />
                        </ProductFlowCtaDecorator>
                      ) : (
                        <ProductFlowCta />
                      )}
                    </>
                  )}
                </>
              ) : (
                <ProductFlowNotification />
              )}
            </ProductFlowControl>
          </div>
        </Section>
      )}
    </>
  )
}

const itemType = PropTypes.shape({
  details: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
})

ProductHeroOther.propTypes = {
  className: PropTypes.string,
  product: productType.isRequired,
  text: PropTypes.string,
  selectionType: PropTypes.string,
  content: PropTypes.string,
  txtAlign: PropTypes.string,
  showPriceReview: PropTypes.bool,
  imageOrientation: PropTypes.oneOf(['portrait', 'landscape']),
  accordionItems: PropTypes.arrayOf(itemType).isRequired,
  accordionHeight: PropTypes.string,
  packageShot: PropTypes.string,
  priceLabel: PropTypes.string,
  infoLabel: PropTypes.string,
  reviewProductCodes: PropTypes.string,
}

export default ProductHeroOther
